import React, { useState, useEffect } from "react";
import style from "./VrtSession.module.sass";
import SessionVRTHome from "../../icons/SessionVRTHome";
import RecapSession from "./RecapSession";
function VrtSessionAssign(props) {
  const { patients, 
    devices, 
    labelUsers, 
    description, 
    labelButton, 
    assignUrl,
    vrtTypologySessionId,
    centerId,
    presetId,
    caa,
    dist,
    startingPointLabel,
    sceneryParams,
    sceneryParamsTranslated
   } = props;

  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [deviceValues, setDeviceValues] = useState({});
  const [devicePatientIds, setDevicePatientIds] = useState({});

  useEffect(() => {
    if (patients.length > 0) {
      setSelectedPatientId(patients[0].id);
    }
  }, [patients]);

  useEffect(() => {
    const initialDeviceValues = devices.reduce((acc, device) => {
      acc[device.id] = "";
      return acc;
    }, {});
    setDeviceValues(initialDeviceValues);

    const initialDevicePatientIds = devices.reduce((acc, device) => {
      acc[device.id] = "";
      return acc;
    }, {});
    setDevicePatientIds(initialDevicePatientIds);
  }, [devices]);

  const handlePatientChange = (event) => {
    setSelectedPatientId(event.target.value);
  };

  const handleDeviceValueChange = (deviceId, value) => {
    setDeviceValues((prevValues) => ({
      ...prevValues,
      [deviceId]: value,
    }));
  };

  const handleClearDeviceValue = (deviceId) => {
    setDeviceValues((prevValues) => ({
      ...prevValues,
      [deviceId]: "",
    }));

    setDevicePatientIds((prevIds) => ({
      ...prevIds,
      [deviceId]: "",
    }));
  };

  const handleButtonClick = () => {
    console.log("Selected Patient ID:", selectedPatientId);
    const selectedPatient = patients.find(patient => String(patient.id) === String(selectedPatientId));
    if (!selectedPatient) {
      console.log("non trovo il paziente");
      return;
    }
    const firstAvailableDevice = devices.find(device => deviceValues[device.id] === "");
    if (!firstAvailableDevice) return;

    handleDeviceValueChange(firstAvailableDevice.id, `${selectedPatient.identifier} `);
    setDevicePatientIds((prevIds) => ({
      ...prevIds,
      [firstAvailableDevice.id]: selectedPatient.id,
    }));
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    const formDataObj = {
      devices:{},
      centerId,
      presetId,
      caa,
      dist,
      vrtTypologySessionId,
      sceneryParams
    };

    devices.forEach(device => {
      if (devicePatientIds[device.id] != '') {
        formDataObj.devices[`device_${device.id}_patient_id`] = devicePatientIds[device.id];
   }

    });
    formDataObj.centerId = centerId
    formDataObj.presetId = presetId
    formDataObj.caa = caa
    formDataObj.dist = dist    
    formDataObj.vrtTypologySessionId = vrtTypologySessionId
    formDataObj.sceneryParams = sceneryParams
    console.log("Form data to be submitted:", formDataObj);
  
    // Replace 'assignUrl' with the actual URL where you want to submit the data
    let response = await fetch(assignUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formDataObj)
    });
    const data = await response.json()

    if (response.ok) {
      window.location.href = data.redirectUrl
          } else {
      setErrors({ ...data.errors })
    }
 
  };


  return (
    <div className={`${style.VrtSessionNew}`}>
    <div className={style.title}>
               {labelUsers}
    </div>
      
    <div>
        <RecapSession sceneryParams={JSON.parse(sceneryParamsTranslated)} />       
         
    </div>

    <div className={style.subtitle}>
               {description}
    </div>

    <div className={style.formField}>
      <div className={style.left}> 
        <select className={`select`}
          id='patient_id'
          name='patient_id'
          style={{ marginBottom: "12px" }}
          value={selectedPatientId}
          onChange={handlePatientChange}
          >
          {patients.map(patients => {
            return (
              <option key={patients.id} value={patients.id}>{patients.identifier}</option>
            )
          })}
        </select>
      </div>
      <div className={`${style.right} ${style.labelField}`}>
        <input className={`${style.buttonSubmit}`}
              type='button'
              value= {labelButton}
              onClick={handleButtonClick}
        />  
      </div>
    </div>

    <form onSubmit={(e) => handleSubmit(e)}>
    <input
              type="hidden"
              name={`vrt_typology_session_id`}
              value={vrtTypologySessionId}
            />

    <div className={style.deviceList}>
        {devices.map((device) => (
          <div key={device.id} className={style.deviceItem}>
            <span className={style.deviceName}>{device.uuid}</span>
            <input
              type="text"
              value={deviceValues[device.id]}
              readonly="readonly"
              onChange={(e) => handleDeviceValueChange(device.id, e.target.value)}
              className={style.deviceInput}
            />
            <button
              type="button"
              onClick={() => handleClearDeviceValue(device.id)}
              className={style.clearButton}
            >
              Clear
            </button>
            <input
              type="hidden"
              name={`device_${device.id}_patient_id`}
              value={devicePatientIds[device.id]}
            />
          </div>
        ))}
      </div>
      { vrtTypologySessionId == 2 &&
        <div className={style.startingPoint}>
          <div className={`${style.left}`}>
            {startingPointLabel}
            </div>
            <div className={style.right}>
            <SessionVRTHome/>
            </div>
          </div>
          }  
        <div className={style.formField}>
          <input
            className={`${style.buttonSubmit} ${style.submitButton}`}
            type="submit"
            value="Submit"
          />
        </div>
    
      </form>    
    </div>
 
  );
}

VrtSessionAssign.propTypes = {};

VrtSessionAssign.defaultProps = {};

export default VrtSessionAssign;
