import React, { useState, useEffect } from "react";
import style from "./VrtSession.module.sass";
import { callApi } from "../../helpers";
import ButtonSelect from "../ButtonSelect/ButtonSelect";
import SelectButtonRelax from "./SelectButtonRelax";
import SelectButtonEducational from "./SelectButtonEducational";
import ToggleButton from "./ToggleButton"; 
function VrtSessionForm(props) {
  const { 
    center,  
    session,  
    tipologies,
    labelTypology,  
    createUrl, 
    title,
    presetRelax,
    sceneryRelax,
    titlePreset,
    titleEducationalLevel,
    levelName,
    presetEducational,
    sceneryEducational

  } = props;


  useEffect(() => {
    // Imposta la tipologia di default a relax (1)
    setSelectedTipology(1);
  }, []);


    const [selectedTipology, setSelectedTipology] = useState(session.pippo);
    const [errors, setErrors] = useState({});
    const [selectedId, setSelectedId] = useState(null); 
    const [selectValues, setSelectValues] = useState({
      code: '',
      time_day: '',
      weather: '',
      sounds: ''
    });
    const [toggleStatuses, setToggleStatuses] = useState({});

    const submitForm = async (e) => {
      e.preventDefault()
      const formData = new FormData()
      const params = Array.from(e.target)
        .filter(el => el.name)
        .reduce((a, b) => ({ ...a, [b.name]: b.value }), {})
      params.selected_preset_id = selectedId; // Aggiungi l'ID selezionato ai parametri
      params.selected_params_values = selectValues;
      Object.keys(toggleStatuses).forEach((key) => {
        params[key] = toggleStatuses[key];
      });
      formData.sessions = params;

      let response = await fetch(createUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      const data = await response.json()
      if (response.ok) {
        let params = new URLSearchParams({
          vrtTypologySessionId: data.vrt_typology_session_id,
          centerId: data.center_id,
          presetId: data.preset_id,
          caa: data.caa,
          dist: data.dist,
          sceneryParams: data.scenery_params
      });
  
      let redirectUrl = `${data.redirectUrl}?${params.toString()}`;
        window.location.href = redirectUrl
            } else {
        setErrors({ ...data.errors })
      }

    }

    const showRelaxPreset = () => {
      return selectedTipology == 1
    }

    const showEducationalPreset = () => {
      return selectedTipology == 2
    }


    const handleToggle = (name, newStatus) => {
      setToggleStatuses((prevStatuses) => ({
        ...prevStatuses,
        [name]: newStatus,
      }));
      console.log(`Toggled ${name} status:`, newStatus);
    };

    const handleSelectValuesChange = (newValues) => {
      setSelectValues(newValues);
      console.log('Updated Select Values:', newValues);
    };
    const choosePresets = () => {
      if (showRelaxPreset()) {
        return (
          <div>
             <SelectButtonRelax presetRelax={presetRelax} scenery={sceneryRelax} titlePreset={titlePreset} onSelect={setSelectedId} onSelectValuesChange={handleSelectValuesChange}/>       
          </div>
        )
      }
      if (showEducationalPreset()) {
        return (
          <div>
             <SelectButtonEducational levelName={levelName} scenery={sceneryEducational} titleEducationalLevel={titleEducationalLevel} presetEducational={presetEducational} onSelect={setSelectedId} onSelectValuesChange={handleSelectValuesChange}/>   
          </div>
        )
      }
      return ''
    }
    const buttonSend = () => {
      
      return <input className={`${style.buttonSubmit}`}
      type='submit'
      value= 'Go'
      />

    
      }

  
  return (
    <div className={`${style.VrtSessionNew}`}>
    <div className={style.title}>
               {title}
    </div>
    <form onSubmit={(e) => submitForm(e)}>  
    <input type="hidden" value={center} name='center_id' />
    <div className={style.formField}>
      <div className={`${style.left} ${style.labelField}`}> {labelTypology}</div>
      <div className={style.right}> 
        <select className={`select`}
          id='vrt_session_typology_id'
          name='vrt_session_typology_id'
          style={{ marginBottom: "12px" }}
          onChange={(e) => { setSelectedTipology(e.target.value);}}>
          <option value="prompt" disabled hidden>scegli il tipo</option>
          {tipologies.map(tipologies => {
            return (
              <option key={tipologies.id} value={tipologies.id}>{tipologies.code}</option>
            )
          })}
        </select>
      </div>
    </div>
    {choosePresets()}
    {showEducationalPreset() && 
    <div>
      <div>
        <ToggleButton initialStatus={true} onToggle={handleToggle} title= "Utilizzo CAA" name="caa"/>
      </div>
        <div>
        <ToggleButton initialStatus={true} onToggle={handleToggle} title= "Presenza di distrattori o obiettivi" name="dist" />
      </div>
      </div>
      }  
    <div style={{ marginBottom: '20px', marginLeft: '15px' }}>
          <span className={`${style.buttonSubmit}`}>
              {buttonSend()}
          </span>
    
    </div>
    </form>
    </div>
    
  );
}

VrtSessionForm.propTypes = {};

VrtSessionForm.defaultProps = {};

export default VrtSessionForm;
