import React, { useState } from "react";
import style from "./VrtSession.module.sass";
import { callApi } from "../../helpers";


function VRTSessions(props) {
  const { 
    addNewText, 
    createUrl, 
    title,
    vrtSessions,
    onTerminateSessionUrl,
    activeSessionsLabel } = props;
 


  // TODO: prevent double calls always
  // TODO: fix all others calls (personal sequences, reacts saved and personal, xclinic saved and personal, etc.)
  const createNewSequence = () => {
      window.location.href = createUrl;
    }
  
  const onTerminateSession = async(e,session) => {
      e.preventDefault();
      console.log("Selected session:", session);

      let response = await fetch(onTerminateSessionUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({session})
      });
      const data = await response.json()
  
      if (response.ok) {
        window.location.href = data.redirectUrl
            } else {
        setErrors({ ...data.errors })
      }
      
    };


  // TODO: cardio - handle xclinic with new button?
  const renderCreateNewPlanBtn = () => {
    return (
      <div className={`${style.createNewPlan}`}>
        <div className={`no-link`}>
          <div className={`custom-height-similar`}>
            <div className={`clickable ${style.button}`}
                 onClick={() => createNewSequence()}>
              {addNewText}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className={`${style.VrtSession}`}>

        <div className={style.title}>
          {title}
        </div>
   
      {createUrl && renderCreateNewPlanBtn()}
      <div className={`${style.title}`}>
        {activeSessionsLabel}
      </div>
      <div className={style.sessionsList}>
        {vrtSessions.map((session, index) => (
          <div key={index} className={style.sessionItem}>
            <div className={style.device}>
             {session.vrtDevice}
            </div>
            <div className={style.patient}>
              {session.patientId}
            </div>
            <div className={style.typology}>
               {session.sessionTypology}
            </div>
            <button
              className={style.terminateButton}
              onClick={(e) => onTerminateSession(e, session.id)}
            >
              Termina
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

VRTSessions.propTypes = {};

VRTSessions.defaultProps = {};

export default VRTSessions;
